<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="tapboxs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><a href="list">Assembly Order List</a></el-breadcrumb-item>
          <el-breadcrumb-item>Import</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <el-button v-hasPermi="['aaoi']" class="download" type="primary" :loading='loadinga' v-if="show" @click="importOrders">import Assembly Orders</el-button> -->
      </div>
      <el-tabs v-model="active" @tab-click='tabbox'>
        <el-tab-pane label="IMPORT" name="1"></el-tab-pane>
        <el-tab-pane label="LOG" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="tablebox" v-if="active==1">
      <div class="bodybox">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 1</p>
              <p>Download Our Inventory List Template File</p>
              <p>This File Has Correct Customer Headings DEAR Needs To Import Your Inventory List.</p>
            </div>
          </div>
          <el-button @click="downloaddata" :loading='btn_loading'>Download Excel Template</el-button>
        </div>
      </div>
      <div class="bodybox">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 2</p>
              <p>Copy Your Inventory List Into The Template</p>
              <p>Export Your Inventory List From Your Old System As Excel File. Using Excel Or Another Spreadsheet Editor, Copy And Paste Your Inventory List From The Exported File Into The Downloaded Template.</p>
            </div>
          </div>
        </div>
        <div class="listbox">
          <div class="titbox">
            <p>Required Fields</p>
            <p>Each Product Is Identified By A Unique ProductCode.</p>
          </div>
          <div class="tabbox">
            <p>FIELD NAME</p>
            <p>WHAT IS IT?</p>
          </div>
          <div class="tablist">
            <p>Location</p>
            <p>Location Where The Stock On Hand Is To Be Landed.</p>
          </div>
          <!-- <div class="tablist">
            <p>Bin</p>
            <p>Location Bin where the stock on hand is to be landed.</p>
          </div> -->
          <div class="tablist">
            <p>SKU</p>
            <p>Product SKU Identifying The Product To Load Dtock On Hand For.</p>
          </div>
          <div class="tablist">
            <p>Quantity</p>
            <p>Number Of FinishedGoods Required</p>
          </div>
          <div class="tablist">
            <p>Work In Progress Date</p>
            <p>Estimated Assembly Start Date</p>
          </div>
          <div class="tablist">
            <p>Completion Date</p>
            <p>Estimated Assembly Completion Time</p>
          </div>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="Optional Fields" name="2">
              <p class="tabtit">You Can Fill In An Optional Fields If You Have Them</p>
              <div class="tabbox">
                <p>OPTIONAL FIELD</p>
                <p>WHAT IS IT?</p>
              </div>
              <div class="tablist">
                <p>Product Name</p>
                <p>The Name Of The Product</p>
              </div>
              <div class="tablist">
                <p>Note</p>
                <p>Note In The Assembly Order</p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="bodybox" style="border:none;">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 3</p>
              <p>Import The Updated Template File</p>
              <p>The File You Import Must Be An Excel File. The Name Of Your File Should End With .xls .xlsx.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bodybox" v-if="show">
        <div class="draggable" v-loading='loading'>
          <el-table :data="tableData" stripe>
            <el-table-column prop="Location" label="Location" width='210' align='center' fixed />
            <!-- <el-table-column prop="Bin" label="Bin" width='150' align='center' show-overflow-tooltip /> -->
            <el-table-column prop="ProductCode" label="SKU" width='150' align='center' show-overflow-tooltip />
            <el-table-column prop="ProductName" label="Product Name" width='150' align='center' show-overflow-tooltip />
            <el-table-column prop="Quantity" label="Quantity" sortable width='160' align='center' show-overflow-tooltip />
            <el-table-column prop="WIPDate" label="WIP Date" sortable width='240' align='center' show-overflow-tooltip />
            <el-table-column prop="CompletionDate" label="Completion Date" sortable width='200' align='center' show-overflow-tooltip />
            <el-table-column prop="Notes" label="Note" align='center' show-overflow-tooltip />
            <el-table-column prop="error" label="Error" align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <img src="../../assets/img/error@2x.png" @click="opendata(scope.row)" width="25" height="25" alt="" v-if="scope.row.error">
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bodybox" style="border:none;">
        <div class="upfile">
          <el-form ref="form" :model="form" :rules="rules" class="formbox">
            <el-form-item prop="file">
              <el-upload :limit='1' class="upload-demo" action='' drag :auto-upload="false" accept=".xlsx, .xls" :on-change="Uploadchange" :on-remove='clearFiles' :file-list="fileList">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drag The File Here, Or<em>Click Upload</em></div>
                <div style="color:red" slot="tip">Tip: Only Import Is Allowed “xls” or “xlsx” Format File！</div>
              </el-upload>
            </el-form-item>
            <el-form-item class="btnbox">
              <el-button type="primary" @click.native.prevent="loadOrders" :loading='btn_loading' v-if="!show">Verify File</el-button>
              <el-button v-hasPermi="['aaoi']" class="download" type="primary" :loading='loadinga' v-if="show" @click="importOrders">Import Assembly Orders</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="tablebox" v-if="active==2">
      <div class="listsbox">
        <div class="draggable" v-loading='loadingl'>
          <el-table :data="tableDatalog" stripe>
            <el-table-column prop="email" label="Upload By" width='300' align='center' fixed />
            <el-table-column prop="create_time" label="Upload Date" sortable width='300' align='center' fixed />
            <el-table-column prop="file_name" label="Uploaded File Name" min-width='300' align='center' show-overflow-tooltip />
            <el-table-column prop="status" label="Upload Status" width='200' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <p>{{scope.row.status==0?'Fail':scope.row.status==1?'Success':''}}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination @current-change='pagechage' background layout="total,prev, pager, next" v-show="totall>0" :current-page.sync="pagel" :page-size="10" :total="totall" />
      </div>
    </div>
  </div>
</template>
<script>
  import { getToken } from '@/utils/auth'
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        active: '1',
        activeName: '2',
        // 导入
        show: false,
        fileList: [],
        form: {
          file: '',
        },
        rules: {
          file: [
            { required: true, trigger: "blur", message: 'Please Upload The File' }
          ]
        },
        tableData: [],
        loading: false,
        loadinga: false,
        btn_loading: false,

        totall: 0,
        pagel: 1,
        tableDatalog: [],
        loadingl: false,
      };
    },
    computed: {
      ...mapGetters(['api_id']),
    },
    created() {
      this.getlist();
      if (sessionStorage.getItem("list_activeName")) {
        this.active = sessionStorage.getItem("list_activeName")
      }
    },
    methods: {
      tabbox(tab) {
        this.active = tab.name
        sessionStorage.setItem("list_activeName", tab.name)
      },
      // 导入 上传file
      Uploadchange(file) {
        this.form.file = file.raw
      },
      clearFiles() {
        this.fileList = []
        this.form.file = ''
        this.tableData = ''
        this.show = false
      },
      //loadOrders
      loadOrders() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.btn_loading = true
            let data = new FormData();
            data.append("file", this.form.file);
            data.append("api_id", this.$store.state.user.api_id);
            data.append("token", getToken());
            this.$post({
              url: "/app/assembly_orders/importList",
              upload: true,
              data,
              success: (res) => {
                this.$message({
                  message: 'Finished',
                  type: 'success'
                });
                this.tableData = res.data
                setTimeout(() => {
                  this.show = true
                  this.btn_loading = false
                }, 1500);
              },
              tip: () => {
                setTimeout(() => {
                  this.btn_loading = false
                }, 1500);
              },
            })
          }
        });
      },
      //导入模板下载
      downloaddata() {
        this.btn_loading = true
        this.$post({
          url: "/app/assembly_orders/getExcelUrl",
          success: (res) => {
            let url = res.data.url1
            window.open(url)
            setTimeout(() => {
              this.btn_loading = false
            }, 1200);
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loading = false
            }, 1200);
          },
        })
      },
      importOrders() {
        this.loadinga = true
        let data = new FormData();
        data.append("file", this.form.file);
        data.append("api_id", this.$store.state.user.api_id);
        data.append("token", getToken());
        this.$post({
          url: "/app/assembly_orders/importAssemblyOrders",
          upload: true,
          data,
          success: (res) => {
            this.$message({
              message: 'Success',
              type: 'success'
            });
            this.form = {
              file: ''
            }
            this.fileList = []
            setTimeout(() => {
              this.pagechage(1)
              this.show = false
              this.loadinga = false
            }, 1500);
          },
          tip: () => {
            setTimeout(() => {
              this.pagechage(1)
              this.loadinga = false
            }, 1500);
          },
        })
      },
      opendata(data) {
        this.$alert(data.error, {
          showClose: false
        });
      },
      getlist() {
        this.loadingl = true
        this.$post({
          url: "/app/import_log/pageList",
          data: {
            api_id: this.$store.state.user.api_id,
            page: this.pagel,
            type: 1,
          },
          success: (res) => {
            this.pagel = res.data.current_page
            this.tableDatalog = res.data.data
            this.totall = res.data.total
            setTimeout(() => {
              this.loadingl = false
            }, 200);
          },
          tip: () => {
            this.loadingl = false
          },
        })
      },
      pagechage(page) {
        this.pagel = page
        this.tableDatalog = []
        this.getlist()
      },
    },
  }
</script>
<style lang='scss' scoped>
  .indexbox {
    width: 100%;
    height: 100%;
    .searchbox {
      width: 100%;
      padding: 10px 30px 0;
      .tapboxs {
        width: 100%;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      /deep/.el-tabs__header {
        margin-bottom: 0;
        .el-tabs__nav-wrap::after {
          display: none;
        }
      }
      background: #fff;
      border-bottom: 1px solid #babdcd;
    }
    .tablebox {
      width: 100%;
      padding: 20px;
      .bodybox {
        width: 100%;
        padding: 20px;
        background: #fff;
        border-bottom: 1px solid #dbdbdb;
        .topbox {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          font-size: 14px;
          p:nth-child(1) {
            color: #333333;
            font-weight: 550;
          }
          p:nth-child(2) {
            color: #666666;
            line-height: 2;
          }
        }
        /deep/.el-button--default {
          color: #19cbbf;
          background-color: #fff;
          border-color: #19cbbf;
          border-radius: 4px;
        }
        .listbox {
          width: 100%;
          margin-top: 15px;
          padding: 10px 15px;
          background: #f1f6fb;
          border-radius: 10px;
          .titbox {
            width: 100%;
            color: #999999;
            font-size: 14px;
            p:nth-child(1) {
              color: #333333;
              font-weight: 550;
            }
            p:nth-child(2) {
              padding: 10px 0 20px;
            }
          }
          .tabbox {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #333333;
            font-size: 14px;
            font-weight: 550;
            padding: 8px 0;
            border-bottom: 1px solid #dbdbdb;
            p:nth-child(1) {
              width: 20%;
            }
          }
          .tablist {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: 550;
            padding: 8px 0;
            line-height: 1.6;
            p:nth-child(1) {
              width: 20%;
              color: #333333;
              font-size: 14px;
            }
            p:nth-child(2) {
              width: 80%;
              color: #666666;
              font-size: 12px;
            }
          }
          /deep/.el-collapse {
            border: none;
            .el-collapse-item__header {
              background: none;
              color: #333333;
              font-size: 14px;
              font-weight: 550;
            }
            .el-collapse-item__content {
              padding-bottom: 0;
            }
            .el-collapse-item__wrap {
              border-bottom: none;
              background: none;
              .tabtit {
                margin-bottom: 20px;
                color: #999999;
                font-size: 14px;
              }
            }
          }
        }
      }
      .bodybox:nth-child(1) {
        border-radius: 10px 10px 0 0;
      }
      .bodybox:last-child {
        border-bottom: none;
        border-radius: 0 0 10px 10px;
        .upfile {
          padding: 10px 0 0;
        }
        /deep/.el-upload-dragger {
          width: 100%;
        }
      }
    }
    .formbox {
      /deep/.el-form-item {
        margin-bottom: 30px !important;
        .el-form-item__content {
          width: 100%;
          .el-button {
            width: 100%;
            height: 48px;
            background: $color;
            border-radius: 5px;
            border-color: $color;
          }
        }
      }
    }
  }
</style>
